import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(localStorage.getItem("UserInfo")),
        login: localStorage.getItem('TOKEN'),
        logo: '',
        config: '',
        changeArtState: 1,
     
        record: {
            table: [],
            select_code: [],
            specialty: []
        },
        artFormList: []
    },
    mutations: {
        setInfo(state, info) {
            state.userInfo = info
        },
        loginOut(state) {
            state.userInfo = ''
        },
        setLogo(state, logo) {
            state.logo = logo
        },
        setConfig(state, data) {
            state.config = data
        },

        setRecord(state, data) {
            state.record = data
        },

        setArtFromList(state, data) {
            state.artFormList = data;
            state.changeArtState = state.changeArtState + 1
        }
    },
    actions: {},
    modules: {}
})
