<template>
  <div id="app">
    <link href="" rel="stylesheet">

    <LuHead />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <Footer />

  </div>
</template>

<script>
import LuHead from "./components/LuHead";
import Footer from "./components/Footer";
import { mapMutations } from "vuex";
export default {
  components: {
    LuHead,
    Footer,
  },
  created() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      window.location.href = "https://bkgaoshou.com/mobile";
    }

    this.getConfig();
  },
  methods: {
    ...mapMutations(["setLogo", "setConfig"]),
    getConfig() {
      let config = this.$store.state.config;
      if (!config) {
        this.$fecth.post("system_setting/getDataById").then((res) => {
          this.setLogo(res.data.logo);
          this.setConfig(res.data);
        });
      }
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: 'PuHuiTi';
  src: url('~@/assets/font/Alibaba_PuHuiTi_2.0_55_Regular_55_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body,
html {
  height: 100%;
  margin: 0;
  font-size: 16px;
}

#app {
  // font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  //   "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-family: 'PuHuiTi', sans-serif;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

.page {
  width: 1200px;
  margin: 0 auto 20px;
  background: white;
}

a {
  text-decoration: none;
}

.breadcrumb {
  font-size: 14px !important;
  padding: 20px 0;
}
</style>
